import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setData } from "../../../store/slices/cartSlice";
import { apiRequest } from "../../../utils/api";
import { calculateVat } from "../../../utils/tax";
import { setFinanceData } from "../../../store/slices/financeSlice";
import { buylineEnable, getData } from "../../../utils/storage";

function PremiumRegistrations({ title, plates, page, plate }) {
  const { pathname } = useLocation();
  const [commission, setCommission] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cart = useSelector((s) => s.cart.data);
  const mainRoute = pathname.split("/")[1];
  const resBuyline = useSelector((s) => s.buyline.data.enable);
  const isBuylineEnable = resBuyline;

  let route =
    title.toLowerCase().indexOf("new style") >= 0
      ? "view"
      : title.toLowerCase().indexOf("prefix") >= 0
      ? "view-prefix"
      : "view-old";

  function addToCart(pl, price) {
    dispatch(
      setData({
        category: route,
        fullNumber: pl.fullNumber,
        price: price,
        vat: (parseFloat(price) * 0.2).toFixed(2),
      })
    );

    localStorage.setItem(
      "cart-plate",
      JSON.stringify({
        category: route,
        fullNumber: pl.fullNumber,
        price: pl.price,
        vat: (parseFloat(pl.price) * 0.2).toFixed(2),
      })
    );
    navigate("/checkout");
  }
  useEffect(() => {
    // plates.map((p) => console.log(p));
    getCommissions();
  }, []);

  async function getCommissions() {
    try {
      let res = await apiRequest.get("/commission/all");
      if (res.data.status === 200) {
        setCommission(res.data.message);
      } else {
        console.log(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function financeCheckout(p, price) {
    dispatch(setFinanceData(true));
    addToCart(p, price);
  }

  return (
    <>
      <div className="premium-registrations">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="premium-title">
                <img crossOrigin="anonymous" src="/img/vip-crown-fill.png" />
                <h2>{title}</h2>
              </div>
            </div>
          </div>
          <div className="premium-table">
            <div className="premium-table-head">
              <ul>
                <li>
                  <h2>initials</h2>
                </li>
                <li>
                  <h2>Price</h2>
                </li>
                <li>
                  <h2>Action</h2>
                </li>
              </ul>
            </div>
            <div className="premium-table-body">
              {plates?.length > 0
                ? plates.map((p, i) => {
                    let dvlaPrice = parseFloat(p.price);
                    let dvlaFee = 80;
                    let bbb = (((dvlaPrice - dvlaFee) / 120) * 20).toFixed(2);
                    let sellPrice = parseFloat(
                      dvlaPrice - dvlaFee - bbb
                    ).toFixed(2);

                    let singleCommissionObject = commission.filter((c) => {
                      return (
                        c.min_price <= dvlaPrice && c.max_price >= dvlaPrice
                      );
                    })[0];

                    let mainCommission = singleCommissionObject?.commission;

                    let commissionPrice = (
                      (parseFloat(sellPrice) * parseFloat(mainCommission)) /
                      100
                    ).toFixed(2);

                    let newPrice = parseFloat(
                      parseFloat(sellPrice) + parseFloat(commissionPrice)
                    ).toFixed(2);

                    return (
                      <ul key={i}>
                        <li>
                          <div className="nik-button">
                            <button type="button" className="btn btn-warning">
                              {p.fullNumber}
                            </button>
                          </div>
                        </li>
                        <li>
                          <h3>£{newPrice}</h3>
                        </li>
                        <li>
                          <div className="view-button">
                            {isBuylineEnable && parseFloat(newPrice) > 250 && (
                              <button
                                type="button"
                                onClick={() => financeCheckout(p, newPrice)}
                                className="btn btn-warning"
                              >
                                Buy With Finance
                              </button>
                            )}
                            <Link
                              to={`/${route}/${p?.fullNumber}`}
                              className="red-link"
                            >
                              <button type="button" className="btn btn-warning">
                                View Plate
                              </button>
                            </Link>

                            <button
                              type="button"
                              onClick={(e) => addToCart(p, newPrice)}
                              className="btn btn-dark"
                            >
                              Buy Now
                            </button>
                          </div>
                        </li>
                      </ul>
                    );
                  })
                : null}
            </div>
          </div>
          {(pathname === "/" || pathname.indexOf("/search") >= 0) && (
            <div className="col-md-12">
              <div className="row mb-5">
                <div className="col-4"></div>
                <div className="view-more-button">
                  <button
                    className="btn btn-dark"
                    onClick={() => navigate(`/${page}/${plate}/2`)}
                  >
                    View More
                  </button>
                </div>
                <div className="col-4"></div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default PremiumRegistrations;
