import React from "react";
import CopyRight from "./CopyRight";
import BottomNav from "./BottomNav";
import { ToastContainer } from "react-toastify";
import { baseUrl, menus, numberPlateTypes } from "./menus";
import { removeFromLast } from "./function";
import PilotReviews from "./PilotReviews";

function Footer() {
  return (
    <div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <PilotReviews />
      <div className="bottom-footer-bg">
        <BottomNav color={`#CEB600`} isVisible={false} />
      </div>
      <div className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3">
              <div className="footer-logo">
                <img crossOrigin="anonymous" src="/img/plates-logo.webp" />
                <p>
                  Here at JDM Plates, we specialise in supplying number plates
                  and number plate accessories for all vehicles within the UK.
                </p>
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/JDMRegPlates"
                      target="_blank"
                    >
                      <span className="color-blu">
                        <i className="bi bi-facebook"></i>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/JDM_Plates" target="_blank">
                      <span className="color-light-blu">
                        <i className="bi bi-twitter"></i>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/jdm_plates/"
                      target="_blank"
                    >
                      <span className="color-dark">
                        <i className="bi bi-instagram"></i>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/jdm-plates-5766a2161/"
                      target="_blank"
                    >
                      <span className="color-light-dark">
                        <i className="bi bi-linkedin"></i>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.pinterest.co.uk/JDM_Plates/"
                      target="_blank"
                    >
                      <span className="color-red">
                        <i className="bi bi-pinterest"></i>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-9">
              <div className="row">
              <div className="col-md-3">
                <div className="quick-links">
                  <h2>Information & Quick Links</h2>
                  <ul>
                    <li>
                      <a href={`${baseUrl}`} target="_blank">
                        Home
                      </a>
                    </li>
                    <li>
                      <a href={`${baseUrl}/social-media-gallery`} target="_blank">
                        Social Media Gallery
                      </a>
                    </li>
                    <li>
                      <a href={`${baseUrl}/articles-posts`} target="_blank">
                        Articles & Posts
                      </a>
                    </li>
                    <li>
                      <a href={`${baseUrl}/my-account`} target="_blank">
                        Accounts & Tracking
                      </a>
                    </li>
                    <li>
                      <a href={`${baseUrl}/contact-us`} target="_blank">
                        Contact Us
                      </a>
                    </li>
                    <li>
                      <a href={`${baseUrl}/faqs`} target="_blank">
                        FAQ's
                      </a>
                    </li>
                    <li>
                      <a href={`${baseUrl}/about`} target="_blank">
                        About Us
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3">
                <div className="quick-links">
                  <h2>Replacement Number Plates</h2>
                  <ul>
                    <li>
                      <a href={`${baseUrl}/printed-number-plates`} target="_blank">
                        Printed Number Plates
                      </a>
                    </li>
                    {removeFromLast(menus, 7)?.map((m) => (
                      <li key={m.first}>
                        <a href={`${baseUrl}/${m.link}`} target="_blank">
                          {m.first === "Electric Car" ? "EV" : m.first} Number{" "}
                          {m.last}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-md-3">
                <div className="quick-links">
                  <h2>Private Number Plates</h2>
                  <ul>
                    {numberPlateTypes?.map((m) => (
                      <li key={m.first}>
                        <a href={`${baseUrl}/${m.link}`} target="_blank">
                          {m.first} {m.last}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-md-3">
                <div className="quick-links">
                  <h2>Legal Stuff</h2>
                  <ul>
                    <li>
                      <a href={`${baseUrl}/terms-conditions`} target="_blank">
                        Terms & Conditions
                      </a>
                    </li>
                    <li>
                      <a href={`${baseUrl}/terms-conditions/#returns-shipping`} target="_blank">
                        Returns & Shipping
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${baseUrl}/privacy-cookie-policy`}
                        target="_blank"
                      >
                        Privacy & Cookie Policy
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${baseUrl}/declaration-of-legality`}
                        target="_blank"
                      >
                        Declaration of Legality
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${baseUrl}/complaints-policy`}
                        target="_blank"
                      >
                        Complaints Policy
                      </a>
                    </li>
                    <li>
      

                      <a href="https://www.jdmplates.co.uk/finance/" target="_blank" rel="noopener noreferrer">
                      Finance
    </a>
                    </li>
                  </ul>
                  <div className="call-of-us">
                    <h2>Call Us Today</h2>
                    <a href="tel:01493%20230023">
                      <span>
                        <i className="bi bi-telephone-fill"></i>
                      </span>
                      01493 230023
                    </a>
                  </div>
                </div>
              </div>
              </div>
              </div>
          </div>
        </div>
      </div>
      <CopyRight />
    </div>
  );
}

export default Footer;
