import React, { useEffect } from "react";
import Routing from "./Routing";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import TopNav from "./utils/TopNav";
import BottomNav from "./utils/BottomNav";
import Navbar from "./utils/Navbar";
import Footer from "./utils/Footer";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { fetchBuyline } from "./store/slices/buylineSlice";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchBuyline());
  }, []);
  return (
    <>
      <TopNav />
      <Navbar />
      <BottomNav color={`#CEB600`} isVisible={true} />
      <Routing />
      <Footer />
    </>
  );
}

export default App;
