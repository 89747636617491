import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    fullNumber: "",
    category: "",
    price: 0,
    vat: 0,
    dvlaFee: 80,
    fastCheckout: 0,
    coupon: "",
    coupon_value: "0",
    isPremium: false,
    plate_type: 0,
    plate_type_value: "",
    coupon_type: "",
  },
};

const appSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
    updateFast: (state, action) => {
      state.data = { ...state.data, fastCheckout: action.payload };
    },
    updatePlate: (state, action) => {
      state.data = {
        ...state.data,
        plate_type: action.payload.price,
        plate_type_value: action.payload.value,
      };
    },
    updateCoupon: (state, action) => {
      state.data = {
        ...state.data,
        coupon: action.payload.coupon,
        coupon_value: action.payload.coupon_value,
        coupon_type: action.payload.coupon_type,
      };
    },
    updateFastCoupon: (state, action) => {
      state.data = {
        ...state.data,
        coupon: action.payload.coupon,
        coupon_value: action.payload.coupon_value,
        coupon_type: "fast",
        fastCheckout: state.data.fastCheckout - action.payload.coupon_value,
      };
    },
    updateServiceCoupon: (state, action) => {
      state.data = {
        ...state.data,
        coupon: action.payload.coupon,
        coupon_value: action.payload.coupon_value,
        coupon_type: "service",
        plate_type: parseFloat(
          parseFloat(action.payload.plate_type) -
            parseFloat(action.payload.coupon_value)
        ).toFixed(2),
      };
    },
    updatePlateServiceData: (state, action) => {
      state.data = {
        ...state.data,
        plate_type: action.payload.plate_type,
      };
    },
    updatePlateFastData: (state, action) => {
      state.data = {
        ...state.data,
        fastCheckout: action.payload.fastCheckout,
      };
    },
  },
});

export const {
  setData,
  updateFast,
  updateCoupon,
  updatePlate,
  updateServiceCoupon,
  updateFastCoupon,
  updatePlateServiceData,
  updatePlateFastData,
} = appSlice.actions;

export default appSlice.reducer;
