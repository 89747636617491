import React from "react";

function CopyRight() {
  return (
    <div>
      <div className="copy-right-footer">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <ul className="visa-list">
                <li>
                  <img crossOrigin="anonymous" src="/img/logos-01.png" />
                </li>
                <li>
                  <img crossOrigin="anonymous" src="/img/logos-02.png" />
                </li>
                <li>
                  <img crossOrigin="anonymous" src="/img/logos-03.png" />
                </li>
                <li>
                  <img crossOrigin="anonymous" src="/img/logos-04.png" />
                </li>
              </ul>

              <p className="mb-3">
              DVLA is a registered trademark of the Driver & Vehicle Licensing Agency. JDM Plates is not affiliated to the DVLA or DVLA Personalised Registrations. JDM Plates is a recognised reseller of DVLA registrations. JDM Plates is also registered with the DVLA to supply physical number plates, i.e. the actual acrylic plate.
              </p>
              <p className="mb-3">
              JDM Plates is a trading style of CJ – Hanley Trading Limited, registered at JDM Plates, Charles Street, Great Yarmouth, NR30 3LA and registered in England & Wales at Companies House under Company Number 11309345 and with the Information Commissioner’s Office for Data Protection under Registration Number ZB822794. CJ – Hanley Trading Limited is authorised and regulated by the Financial Conduct Authority: Firm Reference Number: 1020385. We are a credit broker and not a lender and provide products from a panel of lenders. CJ – Hanley Trading Limited will not charge a fee to customers using its services and will not receive a commission payment from a lender for a referral. Finance is subject to status and terms and conditions apply.
              </p>
              {/* <a href="">Website Designed by PurpleWeb Creative</a> */}
              <p>
              © CJ-Hanley Trading LTD t/a JDM Plates | JDM Plates, Charles Street, Great Yarmouth, NR30 3LA | DVLA RNPS No: 57790 | Company No: 11309345
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CopyRight;
