import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    fullNumber: "",
    plate_type: 0,
    plate_type_value: "",
  },
};

const appSlice = createSlice({
  name: "plateType",
  initialState,
  reducers: {
    setPlateData: (state, action) => {
      state.data = { ...state.data, ...action.payload };
    },
    updatePlateData: (state, action) => {
      state.data = {
        ...state.data,
        plate_type: action.payload.price,
        plate_type_value: action.payload.value,
      };
    },
  },
});

export const { setPlateData, updatePlateData } = appSlice.actions;

export default appSlice.reducer;
