/**
 * Function to calculate and display finance options
 * @param {number} principal - The loan amount
 * @param {number} apr - Annual Percentage Rate (APR) as a percentage
 */
function showFinanceOptions(principal, apr = 19.9) {
  const terms = [6, 12, 24, 36, 48]; // Loan terms in months
  const monthlyRate = apr / 100 / 12; // Monthly interest rate

  console.log(principal);

  // const message = `Finance options for a loan amount of £${principal.toFixed(
  //   2
  // )}:\n`;
  const message = `Finance options Available!`;

  let termsArr = [];

  terms.forEach((term) => {
    if (term == 48) {
      const monthlyPayment = parseFloat((principal * 1.41705) / term);
      const totalAmount = parseFloat(monthlyPayment * term).toFixed(2);
      termsArr.push(
        `6 months Interest-Free finance available at £${parseFloat(
          principal / 6
        ).toFixed(
          2
        )} per month or feel free to spread your payments between 12, 24, 36 or even 48 months with 19.9% interest bearing credit for as little as £${monthlyPayment.toFixed(
          2
        )} per month!`
      );
    }
    // if (term === 6) {
    //   // Interest-free for 6 months
    //   const monthlyPayment = (parseFloat(principal) / term).toFixed(2);
    //   const totalAmount = parseFloat(principal).toFixed(2);

    //   termsArr.push(
    //     `${term} months (Interest-Free): £${monthlyPayment} per month, Total Final Amount: £${totalAmount}`
    //   );
    // } else {
    //   // Interest-bearing options
    //   const monthlyPayment =
    //     (principal * monthlyRate) / (1 - Math.pow(1 + monthlyRate, -term));
    //   const totalAmount = (monthlyPayment * term).toFixed(2);
    //   termsArr.push(
    //     `${term} months: £${monthlyPayment.toFixed(
    //       2
    //     )} per month, Total Final Amount: £${totalAmount}`
    //   );
    // }
  });

  return {
    message,
    termsArr,
  };
}

export { showFinanceOptions };
