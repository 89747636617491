import React, { useState } from "react";
import { Link } from "react-router-dom";
import { baseUrl, menus } from "./menus";

function Navbar() {
  function showHide() {
    document.getElementById("navbarSupportedContent").classList.remove("show");
  }

  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const handleHover = () => {
    setDropdownVisible(true);
  };

  const handleLeave = () => {
    setDropdownVisible(false);
  };

  const handleClick = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <a className="navbar-brand" href={baseUrl} target="_blank">
            <img crossOrigin="anonymous" src="/img/logo.png" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.jdmplates.co.uk/shop/"
                  target="_blank"
                >
                  <span>Number Plate</span>
                  <br />
                  Shop
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.jdmplates.co.uk/number-plate-generator/"
                  target="_blank"
                >
                  <span>Number Plate</span>
                  <br />
                  Generator
                </a>
              </li>
              <li className="nav-item">
                <div
                  className="your-container"
                  onMouseEnter={handleHover}
                  onMouseLeave={handleLeave}
                  onClick={handleClick}
                >
                  <div className="your-trigger">
                    <li className="nav-item">
                      <a href="#" className="nav-link destop">
                        <span>NUMBER PLATE </span>
                        <br />
                        TYPES{" "}
                      </a>
                      <a href="#" className="nav-link phone ">
                        NUMBER PLATE TYPES{" "}
                      </a>
                      <span className="drop-icon">
                        <i className="bi bi-caret-down-fill"></i>
                      </span>
                    </li>
                  </div>
                  {isDropdownVisible && (
                    <div className="dropdown-content-menu">
                      <ul>
                        <li>
                          <a
                            href="https://www.jdmplates.co.uk/printed-number-plates/"
                            target="_blank"
                          >
                            Printed Plates
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.jdmplates.co.uk/smallest-motorcycle-number-plates/"
                            target="_blank"
                          >
                            Motorcycle Plates
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.jdmplates.co.uk/4d-number-plates/"
                            target="_blank"
                          >
                            4D Plates
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.jdmplates.co.uk/3d-number-plates/"
                            target="_blank"
                          >
                            3D Gel Plates
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.jdmplates.co.uk/hex-number-plates/"
                            target="_blank"
                          >
                            Hex Plates
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.jdmplates.co.uk/mission-short-legal-number-plates/"
                            target="_blank"
                          >
                            Short Plates
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.jdmplates.co.uk/import-number-plates/"
                            target="_blank"
                          >
                            Import Plates
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.jdmplates.co.uk/electric-car-number-plates/"
                            target="_blank"
                          >
                            Electric Car Plates
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.jdmplates.co.uk/category/plate-types/"
                            target="_blank"
                          >
                            More Plate Types
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/">
                  <span>Buy</span>
                  <br />
                  Private Reg
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/sell">
                  <span>Sell</span>
                  <br />
                  Your Reg
                </Link>
              </li>
              {/* {menus.map((m) => (
                <li
                  className="nav-item hideMenu"
                  onClick={showHide}
                  key={m.last}
                >
                  {m.first === "Sell" || m.first === "Buy" ? (
                    <>
                      <Link
                        className="nav-link active"
                        to={m.first === "Sell" ? "/sell" : "/"}
                      >
                        <span>{m.first}</span>
                        <br />
                        {m.last}
                      </Link>
                    </>
                  ) : (
                    <a
                      className="nav-link active"
                      aria-current="page"
                      href={`${baseUrl}/${m.link}`}
                      target="_blank"
                    >
                      <span>{m.first}</span>
                      <br />
                      {m.last}
                    </a>
                  )}
                </li>
              ))} */}

              {/* <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.jdmplates.co.uk/contact-us/"
                  target="_blank"
                >
                  <span>Contact</span>
                </a>
              </li> */}
              {/* <li className="nav-item">
                <a
                  className="nav-link"
                  href="tel:01493%20230023"
                  target="_blank"
                >
                  <button type="button" className="btn btn-light call-button">
                    Call us Today
                  </button>
                </a>
              </li> */}
            </ul>

            {/* <ul className="navbar-nav navbar-right ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link" href="#">
                  <span className="cart-nav">
                    <i className="bi bi-cart"></i>
                  </span>
                  <span className="cart-nav-bg">1</span>
                </a>
              </li>
            </ul> */}
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
