import { configureStore } from "@reduxjs/toolkit";
import { api } from "./slices/searchSlice";
import cart from "./slices/cartSlice";
import plateType from "./slices/plateTypeSlice";
import finance from "./slices/financeSlice";
import buyline from "./slices/buylineSlice";
export const store = configureStore({
  reducer: {
    cart: cart,
    plateType,
    finance,
    buyline
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});
