import axios from "axios";

// live
const apiUrl = "https://registrationsapi.jdmplates.co.uk";

// local/test
// const apiUrl = "http://localhost:8080";

const baseUrl = `${apiUrl}/api/v1`;

const apiRequest = axios.create({ baseURL: baseUrl });

export { apiUrl, baseUrl, apiRequest };
