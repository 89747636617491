import React, { useEffect } from "react";
import SecureCheckout from "./components/SecureCheckout";
import { UseSelector, useSelector } from "react-redux";
import { extractNumber } from "../utils/function";

function Checkout() {
  const cart = useSelector((state) => state.cart.data);
  const { category } = cart;
  let numberCat = `${
    category === "view-old"
      ? "Dateless"
      : category === "view-prefix"
      ? "Prefix"
      : category === "view-premium"
      ? "Premium"
      : "New Styles"
  }`;

  useEffect(() => {
    localStorage.setItem(
      "cart-plate",
      JSON.stringify({
        category: category,
        fullNumber: cart.fullNumber,
        price: cart.price,
        vat: cart.vat,
        isPremium: cart.isPremium,
      })
    );
  }, []);

  let onlyNumber = extractNumber(cart.fullNumber);
  let manufactured =
    numberCat === "Dateless"
      ? `any time`
      : numberCat === "Prefix"
      ? `on or after September 1985`
      : numberCat === "Premium"
      ? ``
      : onlyNumber > 50
      ? `on or after September 20${
          parseInt(onlyNumber) - parseInt(50) < 9
            ? `0${parseInt(onlyNumber) - parseInt(50)}`
            : parseInt(onlyNumber) - parseInt(50)
        }`
      : `on or after March 20${onlyNumber}`;
  return (
    <div>
      <SecureCheckout cart={cart} manufactured={manufactured} />
    </div>
  );
}

export default Checkout;
