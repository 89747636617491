import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { apiRequest } from "../../utils/api";

// Step 1: Create an async thunk for hitting the API
export const fetchBuyline = createAsyncThunk(
  "buyline/status",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiRequest.get(`/buyline/enable-status`);
      const { status, message } = response.data;
      if (status == 200) {
        localStorage.setItem("buyline_enable", message.buyline_enable);
        return JSON.parse(message.buyline_enable);
      } else {
        return false;
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

// Step 2: Create the slice
const buylineSlice = createSlice({
  name: "buyline",
  initialState: {
    data: { enable: false },
    loading: false,
    error: null,
  },
  reducers: {
    clearData: (state) => {
      state.data.enable = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBuyline.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBuyline.fulfilled, (state, action) => {
        state.loading = false;
        state.data.enable = action.payload;
      })
      .addCase(fetchBuyline.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch posts";
      });
  },
});

// Step 3: Export the actions and reducer
export const { clearData } = buylineSlice.actions;
export default buylineSlice.reducer;
