import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateFast, updatePlate } from "../../../store/slices/cartSlice";
import { setPlateData } from "../../../store/slices/plateTypeSlice";

function SelfStep({ cart }) {
  const [selectedOption, setSelectedOption] = useState(0);
  const [selectPlate, setSelectPlate] = useState("");
  const dispatch = useDispatch();
  function addExtra(price) {
    dispatch(updateFast(price));
  }

  function addPlateType(value) {
    let price = value.slice(-6, -1);
    dispatch(updatePlate({ price, value }));
    dispatch(setPlateData({ price, value, fullNumber: cart.fullNumber }));
  }

  return (
    <>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingTwo">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseTwo"
            aria-expanded="false"
            aria-controls="collapseTwo"
          >
            <span>2</span>Optional Extras
          </button>
        </h2>
        <div
          id="collapseTwo"
          className="accordion-collapse collapse"
          aria-labelledby="headingTwo"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body">
            <div className="billing-details-contant">
              <div className="row">
                <div className="col-md-12">
                  <div className="optional-extras">
                    <h2>
                      Would you like JDM Plates to assign this registration to a
                      vehicle?
                    </h2>
                    <div className="optional-button">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          checked={selectedOption == 0}
                          value={0}
                          defaultChecked
                          onChange={(e) => {
                            setSelectedOption(e.target.value);
                            addExtra(e.target.value);
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          Do It Yourself
                        </label>
                      </div>
                      <p>
                        We will send you the V750/V778 certificate to hold on
                        retention and assign.
                      </p>
                    </div>
                    <div className="optional-button">
                      <div className="optional-service">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            checked={selectedOption == 24.98}
                            value={24.98}
                            onChange={(e) => {
                              setSelectedOption(e.target.value);
                              addExtra(e.target.value);
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault2"
                          >
                            Fast Track Transfer Service
                          </label>
                        </div>
                        <h3>£24.98</h3>
                      </div>
                      <p>
                        97% of customers choose our fast & comprehensive
                        service:
                      </p>
                    </div>
                    <div className="optional-button-list">
                      <ul>
                        <li>
                          <p>
                            Stress-free. We deal with all the legal paperwork.
                          </p>
                        </li>
                        <li>
                          <p>
                            Transfer completed as quickly as possible or at a
                            date of your choice
                          </p>
                        </li>
                        <li>
                          <p>JDM Plates will update your TAX and MOT records</p>
                        </li>
                        <li>
                          <p>No need to deal with the DVLA</p>
                        </li>
                        <li>
                          <p>
                            You can provide the V5C information after making
                            payment, or provide at a later date if you do not
                            have this yet
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div className="optional-button standard-list">
                      <h2>Standard / 4D 3mm / 3D Gel Plates (Car & Bike)</h2>
                      <p>
                        (Custom plates are available via our main website after
                        checkout is complete.)
                      </p>
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="radio11"
                          name="optradio"
                          value={`Number plates not required - (£00.00)`}
                          defaultChecked={true}
                          onChange={(e) => {
                            setSelectPlate(e.target.value);
                            addPlateType(e.target.value);
                          }}
                        />
                        <label className="form-check-label" htmlFor="radio11">
                          {" "}
                          Number plates not required - (£0.00)
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="radio1"
                          name="optradio"
                          value={`Standard size printed car plates - front and rear -
                          (£29.99)`}
                          onChange={(e) => {
                            setSelectPlate(e.target.value);
                            addPlateType(e.target.value);
                          }}
                        />
                        <label className="form-check-label" htmlFor="radio1">
                          {" "}
                          Standard size printed car plates - front and rear -
                          (£29.99)
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="radio2"
                          name="optradio"
                          value={`Standard size 4D 3mm car plates - front and rear -
                          (£37.00)`}
                          onChange={(e) => {
                            setSelectPlate(e.target.value);
                            addPlateType(e.target.value);
                          }}
                        />
                        <label className="form-check-label" htmlFor="radio2">
                          Standard size 4D 3mm car plates - front and rear -
                          (£37.00)
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="radio3"
                          name="optradio"
                          value={`Standard size 3D gel car plates - front and rear -
                          (£37.00)`}
                          onChange={(e) => {
                            setSelectPlate(e.target.value);
                            addPlateType(e.target.value);
                          }}
                        />
                        <label className="form-check-label" htmlFor="radio3">
                          {" "}
                          Standard size 3D gel car plates - front and rear -
                          (£37.00)
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="radio4"
                          name="optradio"
                          value={`Smallest legal printed motorcycle plate - rear -
                          (£29.99)`}
                          onChange={(e) => {
                            setSelectPlate(e.target.value);
                            addPlateType(e.target.value);
                          }}
                        />
                        <label className="form-check-label" htmlFor="radio4">
                          Smallest legal printed motorcycle plate - rear -
                          (£29.99)
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="radio5"
                          name="optradio"
                          value={`Smallest legal 4D 3mm motorcycle plate - rear -
                          (£34.99)`}
                          onChange={(e) => {
                            setSelectPlate(e.target.value);
                            addPlateType(e.target.value);
                          }}
                        />
                        <label className="form-check-label" htmlFor="radio5">
                          Smallest legal 4D 3mm motorcycle plate - rear -
                          (£34.99)
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="radio6"
                          name="optradio"
                          value={`Smallest legal 3d gel motorcycle plate - rear -
                          (£34.99)`}
                          onChange={(e) => {
                            setSelectPlate(e.target.value);
                            addPlateType(e.target.value);
                          }}
                        />
                        <label className="form-check-label" htmlFor="radio6">
                          Smallest legal 3d gel motorcycle plate - rear -
                          (£34.99)
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="back-button">
                <button
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                  className="btn btn-dark"
                >
                  <span>
                    <img src="/img/arrow-left.png" />
                  </span>
                  Back
                </button>

                <button
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                  className="btn btn-warning"
                >
                  Next
                  <span>
                    <img src="/img/arrow-right.png" />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SelfStep;
